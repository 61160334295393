<template>
  <v-app id='inspire'>
    <nav-bar />

    <v-main class='grey lighten-3'>
      <v-container>
        <v-row>
          <v-col>
            <v-sheet
              min-height='70vh'
              rounded='lg'
              class='pa-5'
            >
              <v-container style='text-align: center'>
                <iframe width='960' height='640'
                        src='https://www.youtube.com/embed/videoseries?list=PLvZq8jaJUgA1cPqRSjSnp4e2Npyz3_a1E'
                        title='YouTube video player' frameborder='0'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                        allowfullscreen></iframe>
              </v-container>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import NavBar from '@/components/NavBar'

export default {
  components: {
    NavBar,
  },
}
</script>
